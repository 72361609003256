<template>
    <div id="check-browser" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Positive Pay" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Reset Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="theme"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-12 col-lg-6 col-xl-4 form-group form-required">
                    <label for="date_range">Date Range</label>
                    <rq-date-range
                        id="txt_check_date_range"
                        v-model:start-date="request.checkTimeFrom"
                        v-model:end-date="request.checkTimeTo"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                        start-date-required
                        end-date-required
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_positive_pay"
            :actions="selectionActions"
            :config="gridConfig"
            title="Positive Pay"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            @edit="onEditItem"
            @process="onProcessItem"
            @print="onPrintItem"
            export-file-name="positive_pay_data"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            rq-filters>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import PositivePayInfo from '@utilities/manage-companies/components/PositivePayInfo';
    import { PositivePay, PositivePaySearchRequestDto, PositivePayProcessRequestDto, PositivePayProcessResultDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "PositivePayList",
        components: { },
        data () {
            return {
                items: [],
                request: new PositivePaySearchRequestDto(),
                activeRequest: new PositivePaySearchRequestDto(),
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true,
                reportParameters: {},
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "CanEditPositivePay",
                    "CanProcessPositivePay",
                    ]);
            },
            hasActiveFilter(){
                return !_.isEqual(new PositivePaySearchRequestDto(this.activeRequest), new PositivePaySearchRequestDto());
            },
            hasFilter(){
                return !_.isEqual(this.request, new PositivePaySearchRequestDto());
            },
            hasReportParamenters(){
                return !_.isEmpty(this.reportParameters);
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.checkTimeFrom")) && _.isNil(_.get(this, "activeRequest.checkTimeTo"))) {
                    return "";
                }
                return `${this.getDate(this.activeRequest.checkTimeFrom, "Anything")} to ${this.getDate(this.activeRequest.checkTimeTo, "Anything")}`;
            },
            readOnly() { return false; },
        },

        watch: {
            "request.checkTimeFrom"(newValue, oldValue) {
                if(newValue === oldValue || _.isEmpty(newValue) ||  _.isEmpty(this.request.checkTimeTo) || this.errorMessage !== this.dateErrorMessage) return;
                this.errorMessage = "";
            },
            "request.checkTimeTo"(newValue, oldValue) {
                if(newValue === oldValue || _.isEmpty(newValue) ||  _.isEmpty(this.request.checkTimeFrom) || this.errorMessage !== this.dateErrorMessage) return;
                this.errorMessage = "";
            },
        },

        methods: {
            base64ToArrayBuffer(base64) {
                var binary_string = _.size(base64) == 0 ? "" : window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },

            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let checkTimeFrom = self.request.checkTimeFrom;
                let checkTimeTo = self.request.checkTimeTo;
                if (_.isNil(checkTimeFrom) || _.isNil(checkTimeTo)) {
                    self.errorMessage = "Must have both a From and To Date";
                    return;
                }
                let request = new PositivePaySearchRequestDto({checkTimeFrom, checkTimeTo});
                let apiPromise = self.$api.EscrowAccountingApi.getPositivePayBanks(request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new PositivePay(i));
                        self.activeRequest = new PositivePaySearchRequestDto(request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Banks.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.setRequestDefaults();
                self.itemTypeName = "Bank";
                self.itemTypeNamePlural = "Banks";
                self.itemKey = "positivePayID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.exportFormats = self.lookupHelpers.getLookupItems(self.lookupItems.EXPORT_FORMATS);
                self.dateErrorMessage = "Must have both a from and to Date";
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            if (!self.localSecurity.CanEditPositivePay)
                                return "Access Restricted.";
                            return false;
                        }
                    },
                    {
                        name: "process",
                        text: "Process & View Report",
                        eventName: "process",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (!self.localSecurity.CanProcessPositivePay)
                                return "Access Restricted."
                            if (_.some(e.data, i => _.isNullOrEmpty(_.get(i, "accountNumber", ""))))
                                return "One or more Banks do not have a valid Account Number.";
                            if (_.some(e.data, ["exportFormat", null]))
                                return "One or more Banks do not have a valid Export Format selected.";
                            return this.hasReportParamenters;
                        }
                    }
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    onSelectionChanged: self.onSelectionChanged,
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "bankCompanyID",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                filterType: "tags",
                                dataSource: self.escrowBanks
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "accountNumber",
                            dataType: "string",
                        },
                        {
                            dataField: "exportFileName",
                            dataType: "string",
                        },
                        {
                            dataField: "makeUniqueName",
                            caption: "Unique FN",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                        },
                        {
                            dataField: "uniqueFileNameExtension",
                            caption: "Unique FN Ext.",
                            dataType: "string",
                        },
                        {
                            dataField: "exportFormat",
                            caption: "Export Format",
                            lookup: {
                                dataSource: self.exportFormats,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new PositivePaySearchRequestDto();
                this.setRequestDefaults();
                this.activeRequest = new PositivePaySearchRequestDto(this.request);
                this.items = [];
                this.reportParameters = {};
                this.refresh();
            },

            onSelectionChanged(e) {
                const self = this;
                if (e.selectedRowsData.length == 0) return;
                self.setReportParameters(e);
            },

            onEditItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                if (!this.localSecurity.CanEditPositivePay) return;
                this.showPositivePaySettingsDialog(new PositivePay(e.data));
            },

            onProcessItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                if (self.readOnly) return;
                this.process(e.data);
            },

            onPrintItem(e) {
                const self = this;
                let reportOptions = {
                    reportName: "Positive Pay",
                    reportPath: "System Reports/File Specific/Positive Pay Report",
                    parameters: self.reportParameters,
                    immediate: true
                };
                _.invoke(this, "$rq.showReport", reportOptions.reportPath, reportOptions);
            },

            onSearch(e) {
                this.fetchData();
            },

            process(items) {
                const self = this;
                if (!self.localSecurity.CanProcessPositivePay) return;
                self.errorMessage = "";
                let checkTimeFrom = self.request.checkTimeFrom;
                let checkTimeTo = self.request.checkTimeTo;
                if (_.isNil(checkTimeFrom) || _.isNil(checkTimeTo)) {
                    self.errorMessage = self.dateErrorMessage;
                    return;
                }
                let bankIDs = _.map(items, "bankCompanyID");
                let request = new PositivePayProcessRequestDto({checkTimeFrom, checkTimeTo, bankIDs});
                let apiPromise = self.$api.EscrowAccountingApi.processPositivePayBanks(request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let files = _.map(result, i => new PositivePayProcessResultDto(i));
                        _.each(files, file => {
                            self.saveToClient(file);
                        });
                        self.onPrintItem();
                    })
                    .catch(error => {
                        self.errorMessage = error.errorMessage;
                    })
                    .finally(() => {
                        self.fetchData();
                    });
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            saveToClient(file) {
                const self = this;
                var binary = self.base64ToArrayBuffer(file.fileContent);
                var blob = new Blob([binary], {type: 'plain/text'});
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(blob, file.fileName);
                else { // Others
                    var a = document.createElement("a")
                    var url = URL.createObjectURL(blob);
                    a.href = url;
                    a.download = file.fileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            setReportParameters(e) {
                let currentBankIds = _.map(e.selectedRowsData, "bankCompanyID");
                let startDate = this.request.checkTimeFrom;
                let endDate = this.request.checkTimeTo;
                this.reportParameters = {
                            p_StartDate: startDate,
                            p_EndDate: endDate,
                            p_Banks: currentBankIds
                        };
            },

            setRequestDefaults() {
                this.request.checkTimeFrom = DateTime.now().startOf("day").toFormat('MM/dd/yyyy');
                this.request.checkTimeTo = DateTime.now().startOf("day").plus({days: 1}).toFormat('MM/dd/yyyy');
            },

            showPositivePaySettingsDialog(item) {
                const self = this;
                let bankName = self.lookupHelpers.getLookupItemName(self.lookupItems.ESCROW_ACCOUNTS, item.bankCompanyID);
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    return form.save()
                            .then(result => {
                                if(!result) return false;
                                let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(result, self.itemKey)]);
                                _.assign(self.items[itemIndex], new PositivePay(result));
                                self.$toast.success({ message: `Positive Pay Settings Saved.` });
                                return true;
                            }).catch(e => {
                                form.errorMessage = e.errorMessage;
                                return false;
                            })
                            .finally(() => {
                                self.refresh();
                            });
                };
                let onCancel = e => {
                    return true;
                };
                self.$dialog.open({
                    title: `Positive Pay Settings: ${bankName}`,
                    height: "450",
                    width: window.innerWidth > 1367 ? "80%" : 1200,
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: PositivePayInfo,
                    onOk: onOk,
                    onCancel: onCancel,
                    props: {
                        positivePay: item,
                        exportFormats: self.exportFormats,
                        includeAccountNumber: true
                    },
                });
            },

            showWarningMessage(msg) {
                const self = this;
                let okHandler = (e) => {
                    self.clear();
                }

                self.$dialog.open({
                    title: "WARNING",
                    height: "auto",
                    width: 400,
                    okOnly: true,
                    scrollable: false,
                    autoFocusOkButton: true,
                    component: {
                        name: "MessageBox",
                        template: `<div class="rq-message-box dialog-confirm-message">${msg}</div>`
                    },
                    onOk: okHandler,
                });
                return true;
            },
        }
    }
</script>
<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_reconciliation_deposits"
        :actions="selectionActions"
        :config="gridConfig"
        :read-only="readOnly"
        class="rq-tab-content-grid"
        :data-source="gridDataSource"
        @rowDoubleClick="onSelectItem"
        @move="onMoveItem"
        @remove="onRemoveItem"
        @select="onSelectItem($event, true)"
        @unselect="onSelectItem($event, false)"
        @navigate="onGotoFile"
        export-file-name="reconciliation_deposits_data"
        integrated-search
        hide-show-column-chooser
        hide-clear-filters
    />
</template>

<script>
    import { mapGetters } from "vuex";
    import SelectReconciliationForm from "../components/SelectReconciliationForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    export default {
        name:"ReconciliationDepositList",
        props: {
            reconciliation: { type: Object, required: true, default: () => {} },
            items: { type: Array, required: true, default: () => [] },
            readOnly: { type: Boolean, required: true, default: false },
        },
        data () {
            return {
                errorMessage: "",
                selectionActions: [],
            };
        },

        watch: {
            reconciliation:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(_.get(newValue, "completed"), _.get(oldValue, "completed"))) {
                        this.initGridConfig();
                        this.setSelectionActions();
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            items:{
                handler: function(newValue, oldValue) {
                    if(!_.isEqual(newValue, oldValue)) {
                        this.refresh();
                    }
                },
                deep: true,
                immediate: false
            },
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.setSelectionActions();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "CheckWriting_ScreenAccess"
                    ]);
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    allowColumnReordering: true,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    wordWrapEnabled: true,
                    columns: [
                        {
                            allowReordering: false,
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            allowReordering: false,
                            dataField: "clearedTemp",
                            dataType: "boolean",
                            caption: "Cleared",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 100,
                            visible: !self.reconciliation.completed,
                            sortIndex: 0,
                            sortOrder: "desc",
                        },
                        {
                            dataField: "transactionNumber",
                            dataType: "string",
                            caption: "Transaction",
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File Number",
                        },
                        DxGridUtils.dateColumn({
                            dataField: "depositDate",
                            caption: "Deposit Date",
                            width: 120,
                        }),
                        {
                            dataField: "description",
                            dataType: "string",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                        },
                        {
                            dataField: "referenceNumber",
                            dataType: "string",
                        },
                        {
                            allowReordering: false,
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 120,
                            minWidth: 120,
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "ClearedTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "ClearedTotalLabel",
                                column: "clearedTemp",
                                alignment: "left",
                                cssClass: "rq-summary-label",
                                displayFormat: "CLEARED",
                                summaryType: "sum"
                            },
                            {
                                name: "DepositTotal",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "DepositTotalLabel",
                                column: "clearedTemp",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                        ],
                        calculateCustomSummary(options) {
                            if (options.name == "ClearedTotal") {
                                options.totalValue = self.reconciliation.receipts;
                            }
                            if (options.name == "DepositTotal") {
                                options.totalValue = self.reconciliation.totalReceipts;
                            }
                        }
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Deposit";
                self.itemTypeNamePlural = "Deposits";
                self.itemKey = "depositID";
            },

            moveDeposits(reconciliationID, ids, newReconciliationID){
                const self = this;
                let apiPromise = self.$api.ReconciliationApi.moveDeposits(reconciliationID, ids, newReconciliationID);
                return self.$rqBusy.wait(apiPromise);
            },

            removeDeposits(reconciliationID, ids){
                const self = this;
                let apiPromise = self.$api.ReconciliationApi.removeDeposits(reconciliationID, ids);
                return self.$rqBusy.wait(apiPromise);
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = 1;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onMoveItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, self.itemKey);
                self.showSelectReconciliationDialog(ids);
            },

            onRemoveItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, self.itemKey);
                let onOk = function (args) {
                    self.removeDeposits(self.reconciliation.reconciliationID, ids)
                        .then(data => {
                            self.errorMessage = "";
                            self.$emit("update-data", data);
                        })
                        .catch(error => {
                            self.errorMessage = `Error Removing ${self.itemTypeName}.`;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }
                let msg = `Are you sure you want to remove this ${self.itemTypeName} from this Reconciliation?`;
                self.$dialog.confirm("Confirm Finalize", msg, onOk, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onSelectItem(e, select) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, self.itemKey);
                if (_.isNil(select)) {
                    select = !e.data.clearedTemp;
                    ids = [_.get(e.data, self.itemKey)];
                }
                self.selectDeposits(self.reconciliation.reconciliationID, ids, select)
                    .then(data => {
                        self.errorMessage = "";
                        self.$emit("update-data", data);
                    })
                    .catch(error => {
                        self.errorMessage = `Error ${select ? 'Clearing' : 'UnClearing'} ${self.itemTypeName}.`;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            refresh(clear=true) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.clear();
                }
                this.gridInstance.refresh();
                this.gridInstance.updateDimensions();
            },

            selectDeposits(reconciliationID, ids, select){
                const self = this;
                let apiPromise = self.$api.ReconciliationApi.selectDeposits(reconciliationID, ids, select);
                return self.$rqBusy.wait(apiPromise);
            },

            setSelectionActions() {
                const self = this;
                if (self.reconciliation.completed) {
                    self.selectionActions = [
                        {
                            name: "remove",
                            text: "Remove",
                            eventName: "remove",
                            requireSelection: true,
                            allowMultiSelection: true,
                            tooltip: `Remove ${self.itemTypeName}`,
                            disabled: function(e) { return self.readOnly;}
                        },
                        { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ self.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.`, disabled: function(e) { return _.isEqual(_.getNumber(e.data, "ordersID", 0), 0); }},
                    ];
                } else {
                    self.selectionActions = [
                        {
                            name: "select",
                            text: "Clear",
                            eventName: "select",
                            requireSelection: true,
                            allowMultiSelection: true,
                            tooltip: `Clear ${self.itemTypeName}`,
                            disabled: function(e) { return self.readOnly || _.some(e.data, ["clearedTemp", true]);}
                        },
                        {
                            name: "unselect",
                            text: "Undo Clear",
                            eventName: "unselect",
                            requireSelection: true,
                            allowMultiSelection: true,
                            tooltip: `Undo Clear ${self.itemTypeName}`,
                            disabled: function(e) { return self.readOnly || _.some(e.data, ["clearedTemp", false]);}
                        },
                        {
                            name: "move",
                            text: "Clear to Other Recon",
                            eventName: "move",
                            requireSelection: true,
                            allowMultiSelection: true,
                            tooltip: `Clear ${self.itemTypeName} to Other Recon`,
                            disabled: function(e) { return self.readOnly;}
                        },
                        { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ self.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.`, disabled: function(e) { return _.isEqual(_.getNumber(e.data, "ordersID", 0), 0); }},
                    ];
                }
            },

            showSelectReconciliationDialog(ids) {
                const self = this;
                let onOk = (e) => {
                    self.moveDeposits(self.reconciliation.reconciliationID, ids, e.component.reconciliationID)
                        .then(data => {
                            self.errorMessage = "";
                            self.$emit("update-data", data);
                        })
                        .catch(error => {
                            self.errorMessage = `Error Moving ${self.itemTypeName}.`;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                let onCancel = (e) => {
                    self.clear();
                };
                self.$dialog.open({
                    title: `Open Reconciliations for ${self.lookupHelpers.getLookupItemName(self.lookupItems.ESCROW_ACCOUNTS, self.reconciliation.bankCompanyID)}`,
                    minHeight: "450",
                    height: "auto",
                    width: "85%",
                    adaptive: true,
                    closeOnEsc: true,
                    component: SelectReconciliationForm,
                    props: {bankCompanyID: self.reconciliation.bankCompanyID, excludeReconciliationID: self.reconciliation.reconciliationID},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            updateDimensions() {
                this.$nextTick(() => {
                    _.invoke(this, "gridInstance.updateDimensions");
                });
            },
        }
    }
</script>
<style lang="scss">
.rq-tab-content-grid-wrapper {
    padding-top: 5px
}
</style>
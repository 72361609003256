<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section :title="`Search ${itemTypeNamePlural}`" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Bank:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="statusFilterDisplay != 'All'" v-rq-tooltip.html.hover.top :title="statusFilterTooltip">
                            <div class="filter-name">Status:</div>
                            <div class="filter-value">{{statusFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="typeFundsFilterDisplay != 'All'" v-rq-tooltip.html.hover.top :title="typeFundsFilterTooltip">
                            <div class="filter-name">Type of Funds:</div>
                            <div class="filter-value">{{typeFundsFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="referenceFilterDisplay.length > 0">
                            <div class="filter-name">{{isWire ? "Wire" : "Reference"}}#:</div>
                            <div class="filter-value">{{referenceFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="amountFilterDisplay.length > 0">
                            <div class="filter-name">Amount:</div>
                            <div class="filter-value">{{amountFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearAllSearch">Reset All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="theme"
                                @click="onClearSearch">Reset</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="theme"
                                :disabled="(errorMessage.length > 0 && !hasFilter) || v$.$error"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div :class="{ 'col col-4 col-xl-4 form-group form-required':true, 'has-error':v$.request.depositStatusIDs.$error }">
                    <rq-action-form-group
                        label="Status"
                        labelFor="dtp_status"
                        :show-action="true"
                        :has-error="v$.request.depositStatusIDs.$error"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearStatus"
                                >{{filterLabel("status")}}
                            </button>
                        </template>
                        <template #default>
                            <dx-tag-box
                                ref="depositStatusTagBox"
                                automation_id="dtp_status"
                                class="form-control"
                                :data-source="depositStatuses"
                                display-expr="name"
                                value-expr="id"
                                :search-enabled="true"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="3"
                                :show-drop-down-button="true"
                                placeholder="All Statuses..."
                                apply-value-mode="useButtons"
                                v-model="v$.request.depositStatusIDs.$model"
                            />
                            <rq-validation-feedback
                                :container="tooltipContainer"
                                :boundary="tooltipContainer"
                                :messages="{'Status is required': !v$.request.depositStatusIDs.required.$invalid }"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div :class="{ 'col col-4 col-xl-3 form-group form-required':true, 'has-error':v$.request.typeFundIDs.$error }">
                    <rq-action-form-group
                        label="Type of Funds"
                        labelFor="tb_type_fund_ids"
                        :show-action="true"
                        :has-error="v$.request.typeFundIDs.$error"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearType"
                                >{{filterLabel("type")}}
                            </button>
                        </template>
                        <template #default>
                            <dx-tag-box
                                automation_id="tb_type_fund_ids"
                                ref="depositTypeTagBox"
                                class="form-control"
                                :data-source="fundTypes"
                                display-expr="name"
                                value-expr="id"
                                :search-enabled="true"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="3"
                                :show-drop-down-button="true"
                                placeholder="All Fund Types..."
                                apply-value-mode="useButtons"
                                v-model="v$.request.typeFundIDs.$model"
                            />
                            <rq-validation-feedback
                                :container="tooltipContainer"
                                :boundary="tooltipContainer"
                                :messages="{'Type of Funds is required': !v$.request.typeFundIDs.required.$invalid }"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Date Range"
                        labelFor="txt_check_date_range"
                        :show-action="true"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearDateRange"
                                >{{filterLabel("date")}}
                            </button>
                        </template>
                        <template #default>
                            <rq-date-range
                                id="txt_check_date_range"
                                v-model:start-date="request.depositDateFrom"
                                v-model:end-date="request.depositDateTo"
                                match-value="before-clear"
                                match-field="end"
                                format="MM/dd/yyyy"
                                type="date"
                                show-calendar-drop-downs
                                show-clear-buttons
                                no-calendars
                                borderless
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Amount Range"
                        labelFor="txt_deposit_amount"
                        :show-action="true"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearAmountRange"
                                >{{filterLabel("amount")}}
                            </button>
                        </template>
                        <template #default>
                            <rq-number-range
                                automation_id="txt_deposit_amount"
                                prepend-icon="fas fa-dollar-sign"
                                :decimals="2"
                                v-model:start-value="request.depositAmountFrom"
                                v-model:end-value="request.depositAmountTo"
                                match-value="before-clear"
                                match-field="end"
                                show-clear-buttons
                                input-groups
                                allow-nulls
                                no-prefix
                                commas
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <rq-action-form-group
                        :label="depositRangeLabel"
                        labelFor="txt_receipt_id"
                        :show-action="hasNumbers"
                        >
                        <template #action>
                            <button
                                type="button"
                                class="btn btn-link btn-theme"
                                @click="onClearNumberRange"
                                >{{filterLabel("number")}}
                            </button>
                        </template>
                        <template #default>
                            <rq-number-range
                                automation_id="txt_receipt_id"
                                :commas="false"
                                :decimals="0"
                                v-model:start-value="request.depositReferenceFrom"
                                v-model:end-value="request.depositReferenceTo"
                                match-value="before-clear"
                                match-field="end"
                                input-groups
                                show-clear-buttons
                                allow-nulls
                                no-prefix
                            />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Bank Info" headerSize="lg" class="browser-filter-section">
            <div class="row">
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_bankReferenceNumber">Bank Ref#</label>
                    <input automation_id="txt_bankReferenceNumber"
                        class="form-control"
                        type="text"
                        v-model="bankInfo.bankReferenceNumber"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_customerReferenceNumber">Ref#</label>
                    <input automation_id="txt_customerReferenceNumber"
                        class="form-control"
                        type="text"
                        :value="bankInfo.customerReferenceNumber"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_transactionDescription">Description</label>
                    <input automation_id="txt_transactionDescription"
                        class="form-control"
                        type="text"
                        :value="bankInfo.transactionDescription"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_transactionAmount">Amount</label>
                    <rq-input-number
                        automation_id="txt_transactionAmount"
                        ref="txt_amount"
                        formatType="money"
                        disabled
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="bankInfo.transactionAmount"
                        />
                </div>
                <div class="col col-2 col-xl-2 form-group">
                    <label for="txt_notes">Issue</label>
                    <input automation_id="txt_issue"
                        class="form-control"
                        type="text"
                        :value="bankInfo.issue"
                        readonly
                        disabled
                        />
                </div>
                <div class="col col-10 col-xl-10 form-group">
                    <label for="txt_notes">Notes</label>
                    <input automation_id="txt_notes"
                        class="form-control"
                        type="text"
                        :value="bankInfo.notes"
                        readonly
                        disabled
                        />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="gridAutomationID"
            :actions="selectionActions"
            :config="gridConfig"
            :title="itemTypeNamePlural"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            @assign="onAssignItem"
            @edit="onEditItem"
            @rowDoubleClick="onRowDoubleClick"
            @selectionChanged="onSelectionChanged"
            @navigate="onGotoFile"
            export-file-name="deposits_data"
            :strikethrough-if-true="['inactive']"
            hide-show-column-chooser
            integrated-search
            :persist-state="false"
            :hide-search="items.length == 0"
            rq-filters>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import { EscrowDepositDto, AutoReconDepositSearchRequest, AutoReconTransactionTempDetailDto, AutoReconUserImportDto }  from "../models";
    import { DepositStatus } from '../../file/check-writing/enums';
    import QuickDepositForm from "../components/QuickDepositForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    const AMOUNT_MISMATCH = "Amount Mismatch";
    const MULTIPLE_MATCHES = "Multiple Matches";
    const NO_MATCH = "No Match Found";

    export default {
        name: "DepositMatchDialog",
        props: {
            bankCompanyID: { type: Number, required: true, default: 0 },
            bankInfo: { type: Object, required: true, default: () => new AutoReconTransactionTempDetailDto() },
            autoReconUserImport: { type: Object, required: true, default: () => new AutoReconUserImportDto() },
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                originalBankInfo: null,
                items: [],
                request: new AutoReconDepositSearchRequest(),
                originalRequest: new AutoReconDepositSearchRequest(),
                activeRequest: new AutoReconDepositSearchRequest(),
                errorMessage: "",
                selectionActions: [],
                fundTypes: [],
                depositStatuses: [],
                filtersExpanded: true,
                depositID: 0
            };
        },

        created() {
            this.$emit("disable-ok");
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initSearch();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            depositRangeLabel(){ return this.isWire ? "Wire# Range" : "Reference# Range";},
            depositStatusTagBoxInstance() { return _.get(this, "$refs.depositStatusTagBox.instance", null); },
            depositTypeTagBoxInstance() { return _.get(this, "$refs.depositTypeTagBox.instance", null); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            gridAutomationID() { return this.isWire ? "tbl_auto_recon_deposit_wires" : "tbl_auto_recon_deposits"; },
            hasFilter(){
                return !_.isEqual(new AutoReconDepositSearchRequest(this.request), new AutoReconDepositSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new AutoReconDepositSearchRequest(this.activeRequest), new AutoReconDepositSearchRequest());
            },
            hasFilterChanged(){
                return !_.isEqual(new AutoReconDepositSearchRequest(this.activeRequest), new AutoReconDepositSearchRequest(this.request));
            },
            hasOriginalDates(){
                return _.isEqual(this.getDate(this.request.depositDateFrom), this.getDate(this.originalRequest.depositDateFrom))
                    && _.isEqual(this.getDate(this.request.depositDateTo), this.getDate(this.originalRequest.depositDateTo));
            },
            hasOriginalAmounts(){
                return _.isEqual(this.request.depositAmountFrom, this.originalRequest.depositAmountFrom)
                    && _.isEqual(this.request.depositAmountTo, this.originalRequest.depositAmountTo);
            },
            hasNumbers(){
                return !_.isNullOrEmpty(this.request.depositReferenceFrom) || !_.isNullOrEmpty(this.originalRequest.depositReferenceTo)
                    || !_.isNullOrEmpty(this.request.depositReferenceFrom) || !_.isNullOrEmpty(this.originalRequest.depositReferenceTo);
            },
            hasOriginalNumbers(){
                return _.isEqual(this.request.depositReferenceFrom, this.originalRequest.depositReferenceFrom)
                    && _.isEqual(this.request.depositReferenceTo, this.originalRequest.depositReferenceTo);
            },
            hasOriginalStatuses(){
                return _.isEqual(this.request.depositStatusIDs, this.originalRequest.depositStatusIDs);
            },
            hasOriginalTypes(){
                return _.isEqual(this.request.typeFundIDs, this.originalRequest.typeFundIDs);
            },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowEscrowAdminBrowserChange",
                    "CanDeleteBookedDeposits",
                    "CheckWriting_ScreenAccess"
                    ]);
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.depositDateFrom")) && _.isNil(_.get(this, "activeRequest.depositDateTo"))) {
                    return "";
                }
                return `${this.getDate(this.activeRequest.depositDateFrom, "Anything")} to ${this.getDate(this.activeRequest.depositDateTo, "Anything")}`;
            },
            amountFilterDisplay(){
                if (_.getNumber(this, "request.depositAmountFrom", 0) > 0 && _.getNumber(this, "request.depositAmountTo", 0) > 0) {
                    return `$${this.request.depositAmountFrom} to $${this.request.depositAmountTo}`;
                }
                if (_.getNumber(this, "request.depositAmountFrom", 0) == 0 && _.getNumber(this, "request.depositAmountTo", 0) > 0) {
                    return `Anything to $${this.request.depositAmountTo}`;
                }
                if (_.getNumber(this, "request.depositAmountFrom", 0) > 0 && _.getNumber(this, "request.depositAmountTo", 0) == 0) {
                    return `$${this.request.depositAmountFrom} to Anything`;
                }
                return "";
            },
            referenceFilterDisplay(){
                if (_.getNumber(this, "request.depositReferenceFrom", 0) > 0 && _.getNumber(this, "request.depositReferenceTo", 0) > 0) {
                    return `${this.request.depositReferenceFrom} to ${this.request.depositReferenceTo}`;
                }
                if (_.getNumber(this, "request.depositReferenceFrom", 0) == 0 && _.getNumber(this, "request.depositReferenceTo", 0) > 0) {
                    return `Anything to ${this.request.depositReferenceTo}`;
                }
                if (_.getNumber(this, "request.depositReferenceFrom", 0) > 0 && _.getNumber(this, "request.depositReferenceTo", 0) == 0) {
                    return `${this.request.depositReferenceTo} to Anything`;
                }
                return "";
            },
            isWire(){
                return _.parseBool(this.bankInfo.isWire);
            },
            bankFilterDisplay(){
                return this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.request.bankCompanyID);
            },
            statusFilterDisplay(){
                let itemNames = _.map(this.activeRequest.depositStatusIDs, id => _.find(this.depositStatuses, { id }).name);
                if (_.size(itemNames) == 0) return "All";
                if (_.size(itemNames) > 2) return `${itemNames.length} Selected`;
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            statusFilterTooltip(){
                let itemNames = _.map(this.activeRequest.depositStatusIDs, id => _.find(this.depositStatuses, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            typeFundsFilterDisplay(){
                let itemNames = _.map(this.activeRequest.typeFundIDs, id => _.find(this.fundTypes, { id }).name);
                if (_.size(itemNames) == 0) return "All";
                if (_.size(itemNames) > 2) return `${itemNames.length} Types Selected`;
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            typeFundsFilterTooltip(){
                let itemNames = _.map(this.activeRequest.typeFundIDs, id => _.find(this.fundTypes, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            readOnly() { return !this.localSecurity.AllowEscrowAdminBrowserChange; },
            tooltipContainer() { return `#${this.dialogId}`; },
        },

        watch: {
            depositID(newValue, oldValue) {
                _.parseNumber(newValue, 0) > 0 ? this.$emit("enable-ok") : this.$emit("disable-ok");
            },
        },

        validations: () => ({
            request: {
                depositStatusIDs: {
                    required
                },
                typeFundIDs: {
                    required
                },
            }
        }),

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData(preselectLast=false) {
                const self = this;
                let apiPromise = self.$api.EBankReconApi.getDeposits(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new EscrowDepositDto(i));
                        self.activeRequest = new AutoReconDepositSearchRequest(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh(preselectLast);
                    });
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } }); },

            filterLabel(range) {
                switch (range) {
                    case "date": return this.hasOriginalDates ? "Clear" : "Revert";
                    case "status": return this.hasOriginalStatuses ? "Select All" : "Revert";
                    case "type": return this.hasOriginalTypes ? "Select All" : "Revert";
                    case "amount": return this.hasOriginalAmounts ? "Clear" : "Revert";
                    case "number": return this.hasOriginalNumbers ? "Clear" : "Revert";
                    default: return "";
                }
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : DateTime.fromFormat(dateStr, "MM/dd/yyyy").toFormat("MM/dd/yyyy");
            },

            initSearch() {
                const self = this;
                self.depositID = 0;
                self.originalBankInfo = _.clone(self.bankInfo);
                self.request.bankCompanyID = self.bankCompanyID;
                self.request.isWire = self.isWire;
                self.request.autoReconUserImportID = self.autoReconUserImport.autoReconUserImportID;
                if (self.isWire) {
                    self.request.typeFundIDs = _.map(_.remove(_.clone(self.fundTypes), i => i.id == 7), "id");
                } else {
                    self.request.typeFundIDs = _.map(_.remove(_.clone(self.fundTypes), i => i.id != 7 && i.id != 8), "id");
                }
                self.request.depositAmountFrom = self.request.depositAmountTo = self.bankInfo.transactionAmount;
                self.request.depositReferenceFrom = self.request.depositReferenceTo = _.parseNumber(self.bankInfo.customerReferenceNumber, null);
                self.request.depositDateFrom = DateTime.fromISO(self.autoReconUserImport.priorReconciliationDate || "01/01/2000").minus({days: self.autoReconUserImport.lookBackDays}).toFormat("MM/dd/yyyy");
                self.request.depositDateTo = DateTime.fromISO(self.autoReconUserImport.reconciliationDate).plus({days: self.autoReconUserImport.forwardDays}).toFormat("MM/dd/yyyy");
                if (_.isEqual(self.bankInfo.issue, MULTIPLE_MATCHES) || _.isEqual(self.bankInfo.issue, AMOUNT_MISMATCH)) {
                    self.request.depositStatusIDs = [DepositStatus.Booked];
                }
                if (_.isEqual(self.bankInfo.issue, NO_MATCH)) {
                    self.request.depositStatusIDs = [DepositStatus.Booked, DepositStatus.Anticipated];
                }
                if (_.isEqual(self.bankInfo.issue, AMOUNT_MISMATCH)) {
                    self.request.depositAmountFrom = self.request.depositAmountTo = null;// since this is a mismatch we already know the amoutns don't match so exclude them
                }
                self.originalRequest = _.clone(self.request);
                self.fetchData();
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = self.isWire ? "Wire" : "Receipt";
                self.itemTypeNamePlural = self.isWire ? "Wires" : "Receipts";
                self.itemKey = "depositID";
                self.depositStatuses = DepositStatus.lookupItems;
                self.fundTypes = _.remove(_.clone(self.lookupHelpers.getLookupItems(self.lookupItems.TYPE_FUNDS, i => i.id == 8)));//remove transfers
                self.selectionActions = [
                    {
                        name: "assign",
                        text: "Assign",
                        eventName: "assign",
                        requireSelection: true,
                        disabled: function(e) {
                            if (_.parseNumber(e.data.reconciliationID, 0) > 0) return `Cannot assign reconciled ${self.itemTypeName}`;
                            if (!_.isEqual(_.parseNumber(self.bankInfo.transactionAmount, -1), _.parseNumber(e.data.amount, 0))) return "Amounts must match";
                            if (!_.isEqual(_.parseNumber(e.data.depositStatus, 0), DepositStatus.Booked)) return "Status must be Booked";
                            return false;
                        }
                    },
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            return self.readOnly;
                        }
                    },
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ self.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.` },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    selection: { mode: "single" },
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#",
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        DxGridUtils.dateColumn({
                            dataField: "depositDate",
                            caption: "Date",
                        }),
                        {
                            dataField: "payor",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            width: 200
                        },
                        {
                            dataField: "referenceNumber",
                            dataType: "string",
                            caption: "Reference#",
                            visible: !self.isWire
                        },
                        {
                            dataField: "wireNumber",
                            dataType: "string",
                            caption: "Wire#",
                            visible: self.isWire
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 100,
                            minWidth: 100
                        },
                        {
                            dataField: "description",
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                        {
                            dataField: "depositStatus",
                            caption: "Status",
                            lookup: {
                                dataSource: self.depositStatuses,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "typeOfFundsDisplay",
                            dataType: "string",
                            caption: "Type of Funds",
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "depositSlipID",
                            dataType: "number",
                            caption: "Deposit Slip",
                        },
                        // DxGridUtils.dateColumn({
                        //     dataField: "depositVerified",
                        //     caption: "Verified Date",
                        // }),
                        {
                            dataField: "usersDisplayName",
                            dataType: "string",
                            caption: "Person",
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onAssignItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                this.depositID = e.data.depositID;
            },

            onClearAmountRange() {
                const self = this;
                if (self.hasOriginalAmounts) {
                    self.request.depositAmountFrom = null;
                    self.request.depositAmountTo = null;
                } else {
                    self.request.depositAmountFrom = self.originalRequest.depositAmountFrom;
                    self.request.depositAmountTo = self.originalRequest.depositAmountTo;
                }
            },

            onClearNumberRange() {
                const self = this;
                if (self.hasOriginalNumbers) {
                    self.request.depositReferenceFrom = null;
                    self.request.depositReferenceTo = null;
                } else {
                    self.request.depositReferenceFrom = self.originalRequest.depositReferenceFrom;
                    self.request.depositReferenceTo = self.originalRequest.depositReferenceTo;
                }
            },

            onClearDateRange() {
                const self = this;
                if (self.hasOriginalDates) {
                    self.request.depositDateFrom = null;
                    self.request.depositDateTo = null;
                } else {
                    self.request.depositDateFrom = self.originalRequest.depositDateFrom;
                    self.request.depositDateTo = self.originalRequest.depositDateTo;
                }
            },

            onClearStatus() {
                const self = this;
                try {
                    if (self.hasOriginalStatuses) {
                        let all = _.map(self.depositStatuses, "id");
                        self.depositStatusTagBoxInstance.option("value", all);
                    } else {
                        self.depositStatusTagBoxInstance.option("value", self.originalRequest.depositStatusIDs);
                    }
                }
                catch(err) {
                    console.error(err);
                }
            },

            onClearType() {
                const self = this;
                try {
                    if (self.hasOriginalTypes) {
                        let all = _.map(self.fundTypes, "id");
                        self.depositTypeTagBoxInstance.option("value", all);
                    } else {
                        self.depositTypeTagBoxInstance.option("value", self.originalRequest.typeFundIDs);
                    }
                }
                catch(err) {
                    console.error(err);
                }
            },

            onEditItem(e) {
                if(!e || !e.data || this.readOnly) return;
                this.errorMessage = "";
                this.showQuickDepositDialog(e.data);
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = 1;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onRowDoubleClick(e) {
                if(!e || !e.data || this.readOnly) return;
                this.gridInstance.selectRows([e.data.depositID], false);
                this.onEditItem(e);
            },

            onSearch() {
                this.errorMessage = "";
                this.depositID = 0;
                if(!this.hasFilter) {
                    this.errorMessage = `Please enter at least one value to search ${this.itemTypeNamePlural}`;
                    return;
                }
                this.fetchData();
            },

            onClearAllSearch() {
                this.onClearSearch();
                this.filtersExpanded = true;
            },

            onClearSearch() {
                this.initSearch();
                this.depositStatusTagBoxInstance.option("value", this.originalRequest.depositStatusIDs);
                this.depositTypeTagBoxInstance.option("value", this.originalRequest.typeFundIDs);
                this.activeRequest = new AutoReconDepositSearchRequest();
                this.items = [];
                this.refresh();
            },

            onSelectionChanged(e) {
                if (e.selectedRowsData.length == 0) {
                    this.depositID = null;
                }
            },

            refresh(preselectLast=false) {
                if(!this.gridInstance) return;
                let depositID = this.gridInstance.getSelectedRowKeys()[0];
                this.clear();
                this.gridInstance.refresh();
                if (preselectLast && _.parseNumber(depositID, 0) > 0) {
                    _.delay(() => {
                        this.gridInstance.selectRows([depositID], false);
                    }, 500);
                }
            },

            save(){
                const self = this;
                let item = _.assign(_.clone(self.bankInfo), {depositID: self.depositID, issue: null});
                let changes = self.getAuditChanges(self.originalBankInfo, item, ["ordersID", "reconciliationID"]);
                if (changes.length == 0) {
                    self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve(true);
                }
                let apiPromise = self.$api.EBankReconApi.saveTransactionDetail(self.bankCompanyID, [item]);
                return self.$rqBusy.wait(apiPromise);
            },

            showQuickDepositDialog(item) {
                const self = this;
                let onOk = (e) => {
                    let form = e.component;
                    return form.save()
                            .then(result => {
                                self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                self.fetchData(true);
                                return true;
                            })
                            .catch(error => {
                                form.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    let form = e.component;
                    if (form.hasChanges && !form.isNew) {
                        let onDiscard = function (args) {
                            self.refresh();
                            return true;
                        };
                        let onSave = (e) => {
                            return form.save()
                                    .then(result => {
                                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                        if(self.hasFilter) {
                                            self.refresh();
                                        }
                                        return true;
                                    })
                                    .catch(error => {
                                        form.errorMessage = error.errorMessage;
                                        console.log(error);
                                        return false;
                                    });
                        };
                        self.$dialog.confirm("Confirm Cancel",
                            `You have pending changes, do you want to save or discard changes?`,
                            onSave,
                            onDiscard,
                            { cancelTitle: 'Discard', okTitle: 'Save'});
                    }
                };
                let title = `Edit ${self.itemTypeName}: ${_.isNullOrEmpty(item.transactionNumber) ? '(blank)' : item.transactionNumber}`;
                self.$dialog.open({
                    title: title,
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: QuickDepositForm,
                    props: {deposit: item},
                    // props: {deposit: item, bankAmount: self.bankInfo.transactionAmount},//revert to this once the feature is approved
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
        }
    }
</script>

<template>
    <div class="content-wrapper" :id="containerID">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="processing
                ? 'tbl_consolidated_checks_processing'
                : 'tbl_consolidated_checks'"
            class="rq-tab-content-grid"
            no-data-text="No consolidated checks found"
            :actions="selectionActions"
            :config="gridConfig"
            search-mode="field"
            :data-source="items"
            :strikethrough-if-true="['isVoid','isStopped']"
            @action="onGridAction"
            @selection-changed="onSelectionChanged"
            integrated-search
            rq-filters
            force-floating-header>
            <template #toolbar>
                <ul class="nav ms-auto me-3">
                    <li :class="{ 'nav-item': true }">
                         <rq-report-button
                            text="View Report"
                            :disabled="printOptionDisabled"
                            :name="reportOptions.title"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
             </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { ref, watch, nextTick } from "vue";
    import { DateTime } from "luxon";
    import { GlobalEventManager } from "@/app.events";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { EscrowAccount } from "@order-entry/contacts/models";
    import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
    import { CheckPrintRequestDto } from "@check-writing/models";
    import { CheckStatus } from "@check-writing/enums";
    import CheckWritingManualPayee from "@check-writing/components/CheckWritingManualPayee";
    import CheckWritingPrintForm from "@check-writing/components/CheckWritingPrintForm";
    import CheckWritingWireTransferForm from "@check-writing/components/CheckWritingWireTransferForm.vue";

    import { ConsolidatedCheckDto, ConsolidatedCheckSelectCheckDto } from "../models";
    import ConsolidatedCheckAssignDialog from "../components/ConsolidatedCheckAssignDialog";
    import {
        useGridInvokerMethods,
        useSecuritySettings
    } from "@/shared/composables/";

    import { useConsolidatedChecksGridActions } from "./composables";

    export default {
        name:"ConsolidatedChecksGrid",
        props: {
            checks: {type: Array, required: true},
            items: {type: Array, required: true},
            processing: {type: Boolean, required: true},
            reportOptions: { type: Object, default: null},
            printOptionDisabled: {type: Boolean, default: true},
            lastFinalizedReconDate: {type: String, required: true},
        },
        setup(props, { emit }) {
            const { dataGrid, invokeGridMethod } = useGridInvokerMethods();
            const { localSecurity } = useSecuritySettings([
                "AllowChangePayee",
                "AllowCheckStop",
                "AllowCheckVoid",
                "AllowDeleteCheck",
                "AllowDeleteCheckFromEscrowAccounting",
                "AllowFinalizedReconModifications",
                "AllowPrintChecks",
                "AllowUndoCheck",
                "AllowVoidTransfers",
                "CanAddChecks",
                "AnticipatedDeposits",
                "VoidCheckDateDays"
            ]);

            const {
                selectionActions,
                onSelectionChanged
            } = useConsolidatedChecksGridActions({
                localSecurity,
                processing: props.processing,
                lastFinalizedReconDate: props.lastFinalizedReconDate
            });

            const errorMessage = ref("");
            const popover = ref({ visible: false, target: null, item: {} });
            const gridPopover = ref({ visible: false, target: null, title: null, items: [], gridConfig: {} });

            function clear() {
                invokeGridMethod("option", "focusedRowIndex", -1);
                invokeGridMethod("clearSelection");
            }

            function refresh(withClear=true) {
                if (withClear) clear();
                invokeGridMethod("refresh");
            }

            const popoverGridConfig = {
                keyExpr: "checksID",
                width: "450px",
                selection: { mode: "none", showCheckBoxesMode: "never" },
                columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#",
                            minWidth: 125
                        },
                        DxGridUtils.dateColumn({
                            dataField: "checkDate",
                            caption: "Date",
                            width: 120
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            caption: "Amount",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            minWidth: 125
                        },
                ],
                summary: {
                    totalItems: [
                        {
                            name: "TotalLabel",
                            column: "checkDate",
                            alignment: "left",
                            displayFormat: "TOTAL",
                            cssClass: "rq-summary-label",
                            summaryType: "sum"
                        },
                        {
                            name: "CheckTotal",
                            column: "amount",
                            alignment: "right",
                            valueFormat: {
                                type: "currency",
                                precision: 2
                            },
                            displayFormat: "{0}",
                            summaryType: "sum"
                        },
                    ]
                }
            };

            watch(errorMessage, (newValue, oldValue) => {
                if(_.isEqual(newValue, oldValue)) return;
                emit("update-error-message", newValue);
            });

            return {
                gridPopover,
                dataGrid,
                localSecurity,
                errorMessage,
                popover,
                selectionActions,
                invokeGridMethod,
                onSelectionChanged,
                popoverGridConfig,
                clear,
                refresh
            };
        },
        created() {
            this.init();
        },
        computed: {
            containerID(){
                return this.processing ? 'consolidated-check-grid-processing' : 'consolidated-check-grid';
            },
        },
        methods: {
            async assignConsolidatedCheck(item) {
                const self = this;
                let consolidatedCheckID = _.get(item, "consolidatedCheckID");
                let apiPromise = self.$api.ConsolidatedChecksApi.getAssignedChecks(consolidatedCheckID);
                let result = await self.$rqBusy.wait(apiPromise);

                let transferredToOrdersID = _.getNumber(item, "consolidatedOrdersID", null) || 0;
                let unassignedChecks = _.filter(self.checks, { transferredToOrdersID });
                let assignedChecks = _.map(result, item => new ConsolidatedCheckSelectCheckDto(item));
                self.$dialog.open({
                    title: `Assign Consolidated Checks`,
                    adaptive: false,
                    height: "90%",
                    width: "95%",
                    component: ConsolidatedCheckAssignDialog,
                    props: { assignedChecks, unassignedChecks, check: item },
                    okTitle: "Save",
                    onOk(e) {
                        return e.component.saveSelectedCheckAssignments()
                            .then(() => {
                                self.$events.$emit("reload-data");
                                self.$toast.success("Check assignments updated successfully.");
                                return true;
                            })
                            .catch(error => {
                                self.$toast.error("An issue occurred while updating check assignments.  Please try again.");
                                console.error(error);
                                return false;
                            });
                    }
                });
            },

            init() {
                const self = this;
                let processingColumnVisibility = {
                    visible: !self.processing,
                    showInColumnChooser: !self.processing
                };

                self.gridConfig = {
                    keyExpr: "consolidatedCheckID",
                    noDataText: "No consolidated checks found",
                    columns: [
                        {
                            dataField: "numberDisplay",
                            caption: "Number",
                            dataType: "string",
                            width: 125,
                            ...processingColumnVisibility
                        },
                        {
                            dataField: "bankName",
                            caption: "Escrow Account",
                            dataType: "string",
                            width: 300,
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            },
                        },
                        {
                            dataField: "payeeName",
                            dataType: "string",
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updatePayeePopover(cellInfo.data, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updatePayeePopover();
                                    }
                                }
                            })
                        },
                        DxGridUtils.dateColumn({
                            dataField: "checkDate",
                            caption: "Date",
                            width: 125,
                            ...processingColumnVisibility
                        }),
                        {
                            dataField: "consolidatedFileNumber",
                            dataType: "string",
                            caption: "File Number",
                            width: 200
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: { type: "currency", precision: 2 },
                            width: 150,
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateGridPopover(cellInfo.data.consolidatedCheckID, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            })
                        },
                        {
                            dataField: "checkStatus",
                            caption: "Status",
                            dataType: "number",
                            lookup: {
                                dataSource: self.processing
                                    ? _.filter(CheckStatus.consolidatedLookupItems, c => _.includes([CheckStatus.None, CheckStatus.Hold], c.id))
                                    : CheckStatus.consolidatedLookupItems,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            width: 125
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate,
                            width: 125,
                            ...processingColumnVisibility
                        },
                        {
                            dataField: "memo",
                            dataType: "string"
                        },
                        { dataField: "voidDate", visible: false, },
                        { dataField: "isWire", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, visible: false },
                        { dataField: "reference", dataType: "string", visible: false },
                        { dataField: "receivingBankName", dataType: "string", visible: false },
                        { dataField: "receivingABA", dataType: "string", visible: false },
                        { dataField: "receivingBankAddress", dataType: "string", visible: false },
                        { dataField: "receivingBankCity", dataType: "string", visible: false },
                        { dataField: "receivingBankState", dataType: "string", visible: false },
                        { dataField: "receivingBankZip", dataType: "string", visible: false },
                        { dataField: "receivingAccountNumber", dataType: "string", visible: false },
                        { dataField: "receivingCustomerName", dataType: "string", visible: false },
                        { dataField: "receivingWireInstructions", dataType: "string", visible: false },
                        { dataField: "offlineBankName", visible: false },
                        { dataField: "offlineABA", dataType: "string", visible: false },
                        { dataField: "offlineBankAddress", dataType: "string", visible: false },
                        { dataField: "offlineBankCity", dataType: "string", visible: false },
                        { dataField: "offlineBankState", dataType: "string", visible: false },
                        { dataField: "offlineBankZip", dataType: "string", visible: false },
                        { dataField: "offlineAccountNumber", dataType: "string", visible: false },
                        { dataField: "offlineFurtherCredit", dataType: "string", visible: false },
                        { dataField: "offlineNotes", dataType: "string", visible: false }
                    ]
                };
            },

            async editMemo(item) {
                this.showMemoDialog(item);
            },

            async printChecks(items, isReport=false) {
                const self = this;
                let apiPromise = self.$api.CompaniesApi.getEscrowAccount(items[0].bankCompanyID);
                let result = await self.$rqBusy.wait(apiPromise);

                let request = new CheckPrintRequestDto({
                    ordersID: items[0].consolidatedOrdersID,
                    bankCompanyID: items[0].bankCompanyID,
                    checksIDs: _.map(items, "consolidatedCheckID"),
                    simulatePrint: true
                })
                self.showPrintFormDialog({
                    bank: new EscrowAccount(result),
                    item: request,
                    isConsolidatedCheck: true,
                    lastFinalizedReconDate: self.lastFinalizedReconDate
                });
            },

            changePayee(item) {
                const self = this;
                let consolidatedCheckId = _.getNumber(item, "consolidatedCheckID", 0);
                if(consolidatedCheckId === 0) return;

                self.$dialog.open({
                    title: "Select Company or Contact",
                    height: "90%",
                    width: "85%",
                    component: CompanyContactLookup,
                    onOk(e) {
                        let result = _.get(e, "originalEvent.data", e.data);
                        if (!result) return false;
                        let apiPromise = self.$api.ConsolidatedChecksApi.savePayeeCompany(consolidatedCheckId, result.companyID);
                        return self.$rqBusy.wait(apiPromise)
                            .then(result => {
                                self.$toast.success("Payee changed successfully.");
                                self.clear();
                                self.$events.$emit("reload-data");
                                return true;
                            })
                            .catch(error => {
                                self.$toast.error("Error saving payee info.");
                                console.error(error);
                                return false;
                            });
                    }
                });
            },

            viewPayee(item) {
                const self = this;
                self.$dialog.open({
                    title: "Payee Information",
                    height: "auto",
                    width: "900",
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    okOnly: true,
                    okTitle: "Close",
                    props: {
                        item: item,
                        readOnly: true
                    }
                });
            },

            goToConsolidatedFile(item) {
                this.$router.push({ name: "check-writing", params: { orderId: item.consolidatedOrdersID } });
            },

            fileTransfer(item) {
                const self = this;
                let consolidatedCheckID = item.consolidatedCheckID || 0;
                self.confirmAction({
                    message: `Are you sure you want to transfer the selected consolidated check back to the original file?`,
                    apiExpr: "ConsolidatedChecksApi.transferToFile",
                    apiParam: consolidatedCheckID,
                    successMessage: "Selected consolidated check(s) transferred successfully.",
                    failureMessage: "An issue occurred while transferring selected consolidated check back to the original file. Please try again."
                });
            },

            wireTransfer(item) {
                const self = this;
                let checksID = _.getNumber(item, "checksID", 0);
                let consolidatedCheckID = _.getNumber(item, "consolidatedCheckID", 0);
                if(checksID === 0 || consolidatedCheckID === 0) return;
                self.$dialog.open({
                    title: "Wire Transfer",
                    height: "80%",
                    width: "80%",
                    minHeight: 700,
                    minWidth: 1200,
                    component: CheckWritingWireTransferForm,
                    props: { checksID, consolidatedCheckID },
                    onOk: e => e.component.save()
                        .then(data => {
                            if(!e.component.isValid()) return false;
                            self.$toast.success("Check(s) transfered successfully.");
                            self.clear();
                            self.$events.$emit("reload-data");
                            return true;
                        }).catch(err => {
                            console.error(err);
                            self.$toast.error("An issue occurred while processing check wire transfer.  Please try again.");
                            return err;
                        })
                });
            },

            voidChecks(items) {
                const self = this;
                if(_.isEmpty(items)) return;
                let voidCheckDateDays = self.localSecurity.VoidCheckDateDays;
                if (voidCheckDateDays > -1) {
                    _.each(items, c => {
                        let now = DateTime.now().startOf("day");
                        let maxDate = DateTime.now().startOf("day");
                        if (!_.isNil(c.datePrinted)) {
                            maxDate = DateTime.fromISO(c.datePrinted).plus({days: voidCheckDateDays});
                        }
                        let daysDiff = now.diff(maxDate, "days").days;
                        if(daysDiff < 0 && Math.abs(daysDiff) > voidCheckDateDays){
                            self.$toast.error("Your Void Check Date Days permissions prevent this check from being voided.");
                            return;
                        }
                    });
                }
                self.confirmStatusChange(items, CheckStatus.Void);
            },

            undoChecks(items) {
                const self = this;
                if(_.isEmpty(items)) return;
                let ids = _.map(items, "consolidatedCheckID");
                let noun = _.gt(items.length, 1) ? "checks" : "check";
                self.confirmAction({
                    message: `Are you sure you want to Undo the selected consolidated ${noun}?`,
                    apiExpr: "ConsolidatedChecksApi.undoChecks",
                    apiParam: ids,
                    successMessage: "Selected consolidated check(s) undone successfully.",
                    failureMessage: `An issue occurred while undoing the selected consolidated ${noun}. Please try again.`
                });
            },

            async updateGridPopover(consolidatedCheckID=null, target=null) {
                const self = this;

                let newID = target;
                let lastID = _.get(self.gridPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    let apiPromise = self.$api.ConsolidatedChecksApi.getAssignedChecks(consolidatedCheckID);
                    let items = await self.$rqBusy.wait(apiPromise);
                    gridPopover.target = newID;
                    gridPopover.gridConfig = self.popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Assigned Checks";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }

                await nextTick();

                self.gridPopover = gridPopover;
                GlobalEventManager.emit("show-grid-popover", self.gridPopover);
            },

            async updatePayeePopover(item=null, target=null) {

                self.popover = { visible: false, target: null, item: {} };

                if(_.isNil(target) && _.isNil(item)) {
                    GlobalEventManager.emit("show-popover", self.popover);
                    return;
                }

                await nextTick();

                let popoverValue = {
                    item: _.pick(item, [
                        "payeeCompanyID",
                        "payeeName",
                        "payeeAddress1",
                        "payeeAddress2",
                        "payeeCity",
                        "payeeState",
                        "payeeZip"
                    ]),
                    target,
                    visible: true
                };
                self.popover = popoverValue;
                GlobalEventManager.emit("show-popover", popoverValue);
            },

            deleteChecks(items) {
                const self = this;
                if(_.isEmpty(items)) return;
                let ids = _.map(items, "consolidatedCheckID");
                let noun = _.gt(items.length, 1) ? "checks" : "check";
                self.confirmAction({
                    message: `Are you sure you want to delete the selected consolidated ${noun}?`,
                    apiExpr: "ConsolidatedChecksApi.deleteChecks",
                    apiParam: ids,
                    successMessage: "Selected consolidated check(s) deleted successfully.",
                    failureMessage: `An issue occurred while deleting the selected consolidated ${noun}. Please try again.`
                });
            },

            confirmStatusChange(items, checkStatus) {
                const self = this;
                if(_.isEmpty(items)) return;
                let ids = _.map(items, "consolidatedCheckID");
                let noun = _.gt(items.length, 1) ? "checks" : "check";
                let action = "Status Change";
                switch(checkStatus){
                    case CheckStatus.Hold:
                        action = "(reverse) Hold";
                        break;
                    case CheckStatus.Void:
                        action = "Void";
                        break;
                    case CheckStatus.StopPay:
                        action = "Stop Pay";
                        break;
                }
                let message = `Are you sure you want to set the check status to ${action} on the selected ${noun}?`;
                let successMessage = `Check status set to ${action} on the selected consolidated ${noun}`;
                let failureMessage = `An issue occurred while changing the status of the selected consolidated ${noun} to ${action}. Please try again.`;
                self.confirmAction({
                    message,
                    successMessage,
                    failureMessage,
                    apiExpr: "ConsolidatedChecksApi.saveCheckStatus",
                    apiParams: [ids, checkStatus]
                });
            },

            confirmAction({ message, apiExpr, apiParam=null, apiParams=null, successMessage, failureMessage }) {
                const self = this;
                self.$dialog.confirm(
                    "Confirm",
                    message,
                    () => {
                        let apiPromise = _.isNil(apiParam)
                            ? _.invoke(self.$api, apiExpr, ...apiParams)
                            : _.invoke(self.$api, apiExpr, apiParam);
                        self.$rqBusy.wait(apiPromise)
                            .then(() => {
                                self.$toast.success(successMessage);
                                self.clear();
                                self.$events.$emit("reload-data");
                            })
                            .catch(err => {
                                self.$toast.error(failureMessage);
                                console.error(err);
                            });
                        return true;
                    },
                    null,
                    { cancelTitle: "No", okTitle: "Yes"}
                );
            },

            async onSave(e) {
                const self = this;
                try {
                    let success = await self.saveSelectedCheckAssignments();
                    GlobalEventManager.saveCompleted({ success });
                }
                catch(err) {
                    GlobalEventManager.saveCompleted({ success: false });
                }
            },

            async onUndoChecks(checkIDs) {
                const self = this;
                //the doc viewer uses checksID not consolidatedCheckID so we'll have to translate first
                //we also notified them in doc viewer to confirm undo, so we'll bypass confirmAction()
                let items = _.filter(self.checks, c => _.includes(checkIDs, c.checksID));
                if(_.isEmpty(items)) return;
                let ids = _.map(items, "consolidatedCheckID");
                try {
                    let apiPromise = self.$api.ConsolidatedChecksApi.undoChecks(ids);
                    await self.$rqBusy.wait(apiPromise);
                    self.clear();
                }
                catch(error) {
                    console.error(error);
                    self.$toast.error("An issue occurred while undoing the selected checks.");
                }
                self.$events.$emit("reload-data");
            },

            onGridAction(e) {
                let actionName = _.get(e, "action.name", null);
                if(!actionName) return;
                if(e.action.disabled) return;
                switch(actionName) {
                    case "assign": this.assignConsolidatedCheck(e.data); break;
                    case "go-to-file": this.goToConsolidatedFile(e.data); break;
                    case "print-check": this.printChecks(e.data); break;
                    case "edit-memo": this.$emit("edit-memo", {consolidatedCheckID: e.data.consolidatedCheckID}); break;
                    case "print-report": this.printChecks(e.data, true); break;
                    case "change-payee": this.changePayee(e.data); break;
                    case "view-payee": this.viewPayee(e.data); break;
                    case "file-transfer": this.fileTransfer(e.data); break;
                    case "wire-transfer":
                    case "wire-details": this.wireTransfer(e.data); break;
                    case "void": this.voidChecks(e.data); break;
                    case "stop-pay": this.confirmStatusChange(e.data, CheckStatus.StopPay); break;
                    case "hold": this.confirmStatusChange(e.data, CheckStatus.Hold); break;
                    case "undo": this.undoChecks(e.data); break;
                    case "delete": this.deleteChecks(e.data); break;
                }
            },

            showPrintFormDialog(props) {
                const self = this;
                self.$dialog.open({
                    title: "Print Checks",
                    width: 800,
                    adaptive: true,
                    closeOnEsc: true,
                    component: CheckWritingPrintForm,
                    props,
                    onOk(e) {
                        if (!e.component.isValid()) return false;
                        return e.component.printChecks()
                            .then(data => {
                                let consolidatedChecks = _.map(data.checks, item => new ConsolidatedCheckDto(item));
                                let checks = _.map(data.unassignedChecks, item => new ConsolidatedCheckSelectCheckDto(item));
                                self.$emit("update-data", {consolidatedChecks, checks});
                                return true;
                            }).catch(err => {
                                console.error(err);
                                return err;
                            });
                    }
                });
            },
        }
    }
</script>
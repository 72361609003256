<template>
    <div id="check-browser" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Escrow Trial Balances" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != ''">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="noteFilterDisplay != ''">
                            <div class="filter-name">Memos Only:</div>
                            <div class="filter-value">{{noteFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-4 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.bankCompanyID"
                        placeholder="All Escrow Accounts..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-4 form-group pt-4">
                    <b-form-checkbox
                        automation_id="chk_showNoteOnly"
                        id="showNoteOnly"
                        v-model="request.noteOnly"
                        @change="onNoteOnlyChanged"
                        >Memos Only</b-form-checkbox>
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_escrow_trial_balances"
            :actions="selectionActions"
            :config="gridConfig"
            title="Escrow Trial Balances"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            @edit-memo="onEditMemo"
            @navigate="onGotoFile"
            @rowDoubleClick="onEditMemo"
            export-file-name="escrow_trial_balances_data"
            integrated-search
            :hide-search="items.length == 0"
            rq-filters>
            <template #toolbar>
                <ul class="nav ms-2 me-auto">
                    <li class="nav-item">
                        <rq-report-button
                            text="View Report"
                            :disabled="readOnly || items.length == 0 || hasFilterChanged"
                            :path="reportOptions.reportPath"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_MUTATIONS } from "@/store/mutations";
    import { EscrowTrialBalanceDto, EscrowTrialBalanceSearchRequest }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

    export default {
        name: "EscrowTrialBalanceList",
        components: { },
        data () {
            return {
                items: [],
                request: new EscrowTrialBalanceSearchRequest(),
                activeRequest: new EscrowTrialBalanceSearchRequest(),
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true,
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "CanEnterEditAccountingEscrowMemo",
                    "CheckWriting_ScreenAccess"
                    ]);
            },
            hasFilter(){
                return !_.isEqual(new EscrowTrialBalanceSearchRequest(this.request), new EscrowTrialBalanceSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new EscrowTrialBalanceSearchRequest(this.activeRequest), new EscrowTrialBalanceSearchRequest());
            },
            bankFilterDisplay(){
                return _.isNil(this.activeRequest.bankCompanyID) ? "" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            noteFilterDisplay(){
                return this.activeRequest.noteOnly ? "Yes" : "No";
            },
            readOnly() { return !this.localSecurity.IsAdmin && !this.localSecurity.IsEscrowAdmin; },
            userBankCompanyID(){
                let branchID = _.getNumber(this.lookupHelpers.getBranch(this.user.branchID), "bankCompanyID", 0);
                return branchID == 0 ? null : branchID;
            },
            hasFilterChanged(){
                return !_.isEqual(new EscrowTrialBalanceSearchRequest(this.activeRequest), new EscrowTrialBalanceSearchRequest(this.request));
            },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                        reportPath: "System Reports\\File Specific\\ETB Browser",
                        title: 'ETB Browser',
                        disabled: true,
                        immediate: true,
                        parameters: {
                            p_Banks: this.request.bankCompanyID || '',
                            //Represents if Memos Only Checked or not.
                            p_Bool: this.request.noteOnly
                        },
                });

                return reportOptions;
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let bankCompanyID = _.isNil(self.request.bankCompanyID) ? 0 : self.request.bankCompanyID;
                let apiPromise = self.$api.EscrowAccountingApi.getEscrowTrialBalances(bankCompanyID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new EscrowTrialBalanceDto(i));
                        self.activeRequest = new EscrowTrialBalanceSearchRequest(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Escrow Trial Balance";
                self.itemTypeNamePlural = "Escrow Trial Balances";
                self.itemKey = "ordersID";
                self.regions = self.lookupHelpers.getLookupItems(self.lookupItems.REGIONS);
                self.branches = self.lookupHelpers.getLookupItems(self.lookupItems.BRANCHES);
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.request.bankCompanyID = self.activeRequest.bankCompanyID = self.userBankCompanyID;
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit Memo",
                        eventName: "edit-memo",
                        requireSelection: true,
                        tooltip: `Add/Edit Escrow Memo.`,
                        disabled: function(e) {
                            return (!self.localSecurity.CanEnterEditAccountingEscrowMemo) ? 'Access Restricted' : false;
                        }
                    },
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ this.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.` },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "gfNo",
                            caption: "File#",
                        },
                        {
                            dataField: "regionID",
                            caption: "Region",
                            lookup: {
                                dataSource: self.regions,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            visible: false,
                        },
                        {
                            dataField: "branchID",
                            caption: "Branch",
                            lookup: {
                                dataSource: self.branches,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            visible: false,
                        },
                        {
                            dataField: "receipts",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 130,
                            minWidth: 130
                        },
                        {
                            dataField: "disbursements",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 130,
                            minWidth: 130
                        },
                        {
                            dataField: "accountBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 140,
                            minWidth: 140
                        },
                        DxGridUtils.dateColumn({
                            dataField: "lastActivityDate",
                            caption: "Last Escrow Activity",
                        }),
                        {
                            dataField: "bankCompanyID",
                            caption: "Escrow Account",
                            lookup: {
                                dataSource: self.escrowBanks,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "escrowMemo",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            width: 250
                        },
                        {
                            dataField: "isLocked",
                            caption: "File Lock",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "isEscrowLocked",
                            caption: "Escrow Lock",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "isGlobal",
                            caption: "Global",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false,
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        let items = self.items;
                        if (self.request.noteOnly) {
                            items = _.filter(items, ['hasNote', true]);
                        }
                        return Promise.resolve(items);
                    },
                };
            },

            onAddEscrowMemo(e) {
                this.showEscrowMemoDialog();
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = 0;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onEditMemo(e) {
                if(!e || !e.data || !this.localSecurity.CanEnterEditAccountingEscrowMemo) return;
                this.errorMessage = "";
                this.showEscrowMemoDialog(e.data);
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new EscrowTrialBalanceSearchRequest();
                this.activeRequest = new EscrowTrialBalanceSearchRequest();
                this.items = [];
                this.refresh();
            },

            onSearch() {
                this.fetchData();
            },

            onNoteOnlyChanged() {
                this.refresh();
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            saveEscrowMemo(ordersID, memo){
                const self = this;
                if (!self.localSecurity.CanEnterEditAccountingEscrowMemo) return;
                let apiPromise = self.$api.OrdersApi.saveEscrowMemo(ordersID, memo);
                return self.$rqBusy.wait(apiPromise);
            },

            showEscrowMemoDialog(item) {
                const self = this;
                let originalMemo = item.escrowMemo;
                let ordersID = item.ordersID;
                self.$dialog.promptInput({
                    title: "Escrow Memo",
                    value: originalMemo,
                    isRequired: false,
                    multiline: true,
                    okTitle: "Save",
                    onOk: e => {
                        let memo = e.component.inputValue;
                        if (_.isEqual(originalMemo, memo)) {
                            self.$toast.info("No Changes Detected.");
                            return true;
                        }
                        return self.saveEscrowMemo(ordersID, memo)
                            .then(() => {
                                self.$toast.success("Saved Escrow Memo Successfully.");
                                let item = _.find(self.items, [self.itemKey, ordersID]);
                                item.escrowMemo = memo;
                                self.refresh();
                                return true;
                            })
                            .catch(error => {
                                self.$toast.error("Error saving Escrow Memo.");
                                console.error(error);
                                return error;
                            });
                    }
                });
            },

            showWarningMessage(msg) {
                const self = this;
                let okHandler = (e) => {
                    self.clear();
                }

                self.$dialog.open({
                    title: "WARNING",
                    height: "auto",
                    width: 400,
                    okOnly: true,
                    scrollable: false,
                    autoFocusOkButton: true,
                    component: {
                        name: "MessageBox",
                        template: `<div class="rq-message-box dialog-confirm-message">${msg}</div>`
                    },
                    onOk: okHandler,
                });
                return true;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .w-47 { width: 25%; }
</style>